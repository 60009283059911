import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik-next";
import * as Yup from "yup";
import { delay } from "lodash";
import { TiPrinter } from "@react-icons/all-files/ti/TiPrinter";

import { Modal, ModalBody, ModalHeader, ModalActions, FormInput } from "Molecules";
import { Alert, AlertBody, AlertTitle, AlertIcon } from "Molecules";
import { Button, Stack, Flex, Fade } from "Atoms";
import { usePosStore, useQoplaStore } from "Stores";
import { SET_POS_RECEIPT_TYPE_ON_ORDER } from "GraphQLMutations";
import { IModalContext, useLanguage } from "Providers";
import { POSReceiptType } from "Constants";
import { printReceipt } from "TempUtils";
import { useEffectOnce, useMothershipMutation } from "Hooks";
import { pingReceiptPrinter } from "../../utils";

type DigitalReceiptsFormValues = {
    email: string;
    POSReceiptType: string;
};

type Props = {
    order: any;
    optionalReceipts: boolean;
    confirmOptionalReceipts: () => Promise<any>;
};

enum PrinterStates {
    "CONTACTING",
    "AVAILABLE",
    "UNAVAILABLE",
    "NONE"
}

export const PosDigitalReceiptsModal: React.FC<IModalContext<Props>> = ({
    modalContent: { order, optionalReceipts, confirmOptionalReceipts },
    closeModal
}) => {
    const [printerState, setPrinterState] = useState<PrinterStates>(PrinterStates.NONE);

    const [setPOSReceiptTypeOnOrder, { data, error }] = useMothershipMutation(SET_POS_RECEIPT_TYPE_ON_ORDER, {
        fetchPolicy: "no-cache"
    });
    const hasSuccessfullyAttachedPOSReceiptType = !error && data?.setPOSReceiptTypeOnOrder;

    const { selectedPos } = usePosStore();
    const { selectedShop } = useQoplaStore();
    const { translate } = useLanguage();

    const validationSchema = Yup.object().shape({
        POSReceiptType: Yup.string(),
        email: Yup.string().when("POSReceiptType", {
            is: POSReceiptType.DIGITAL,
            then: Yup.string().email(translate("invalidEmail")),
            otherwise: Yup.string().notRequired()
        })
    });

    const emailEndings = ["@gmail.com", "@hotmail.com", "@yahoo.com", "@outlook.com", "@icloud.com", "@qopla.com"];

    useEffectOnce(() => {
        const handleCheckIfPrinterIsAvailable = async () => {
            const isPrinterAvailable = await pingReceiptPrinter(selectedPos?.receiptPrinter);

            if (!isPrinterAvailable) {
                setPrinterState(PrinterStates.UNAVAILABLE);
            } else {
                setPrinterState(PrinterStates.AVAILABLE);
            }
        };

        handleCheckIfPrinterIsAvailable();
    });

    useEffect(() => {
        if (hasSuccessfullyAttachedPOSReceiptType) {
            onCloseModal();
        }
    }, [hasSuccessfullyAttachedPOSReceiptType]);

    const handlePrintReceipt = async () => {
        const restaurantOnlineOrderUrl = selectedShop!.settings.onlineSettings.restaurantOnlineOrderUrl ?? null;

        if (optionalReceipts) {
            const shouldPrintOptionalReceipt = await confirmOptionalReceipts();
            if (shouldPrintOptionalReceipt) {
                printReceipt(order, selectedPos!.receiptPrinter, null, null, null, restaurantOnlineOrderUrl, null);
            }
        } else {
            printReceipt(order, selectedPos!.receiptPrinter, null, null, null, restaurantOnlineOrderUrl, null);
        }
    };

    const handleSetPOSReceiptTypeOnOrder = async (values: DigitalReceiptsFormValues) => {
        try {
            await setPOSReceiptTypeOnOrder({
                variables: {
                    orderId: order.id,
                    posReceiptType: values.POSReceiptType,
                    email: values.email
                }
            });

            if (values.POSReceiptType === POSReceiptType.PHYSICAL) {
                await handlePrintReceipt();
            }
        } catch (error) {
            console.error("Error when adding POS receipt type on order with order id", order.id);
        }
    };

    const onCloseModal = () => {
        delay(() => closeModal("posDigitalReceiptsModal"), 1000);
    };

    return (
        <Modal open>
            <Formik<DigitalReceiptsFormValues>
                validationSchema={validationSchema}
                initialValues={{ email: "", POSReceiptType: POSReceiptType.DIGITAL }}
                onSubmit={values => handleSetPOSReceiptTypeOnOrder(values)}
            >
                {({ setFieldValue, values, isValid, isSubmitting, submitForm, setValues, setErrors, setTouched }) => {
                    const onChooseNoReceipt = async () => {
                        await setErrors({}); // await actually HAS effect on it
                        setValues({ email: "", POSReceiptType: POSReceiptType.NONE });
                        setTouched({});
                        submitForm();
                    };
                    const onChoosePhysicalReceipt = () => {
                        setValues({ email: "", POSReceiptType: POSReceiptType.PHYSICAL }, false);
                        submitForm();
                    };

                    const hasChosenNoReceipt = values.POSReceiptType === POSReceiptType.NONE;
                    const isDigitalReceipt = values.POSReceiptType === POSReceiptType.DIGITAL;

                    return (
                        <>
                            <Fade
                                in={hasSuccessfullyAttachedPOSReceiptType && !hasChosenNoReceipt}
                                style={{ zIndex: 1500 }}
                                unmountOnExit
                            >
                                <Alert
                                    position="absolute"
                                    zIndex="1500"
                                    status="success"
                                    variant="subtle"
                                    flexDirection="column"
                                    justifyContent="center"
                                    textAlign="center"
                                    height="100%"
                                    width="100%"
                                    rounded="lg"
                                >
                                    <AlertIcon size="50px" mr={0} />
                                    <AlertTitle mt={4} mb={2} fontSize="2xl">
                                        {translate(isDigitalReceipt ? "receiptHasBeenSent" : "receiptHasBeenPrinted")}
                                    </AlertTitle>
                                    {isDigitalReceipt && (
                                        <AlertBody maxWidth="sm" fontSize="lg">
                                            {translate("receiptHasBeenSentTo")} {values.email}
                                        </AlertBody>
                                    )}
                                </Alert>
                            </Fade>
                            <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
                                {translate("doYouWantReceipt")}
                                <Button
                                    themeColor="red"
                                    size="lg"
                                    onClick={onChooseNoReceipt}
                                    isLoading={hasChosenNoReceipt}
                                >
                                    {translate("noThanks")}
                                </Button>
                            </ModalHeader>
                            <Form>
                                <ModalBody pb={2}>
                                    <FormInput name="email" formLabel="Email" isMandatory size="lg" fullWidth />
                                    <Flex mt="-0.75rem" wrap="wrap">
                                        {emailEndings.map(emailEnding => (
                                            <Button
                                                key={emailEnding}
                                                mr={3}
                                                mb={3}
                                                size="md"
                                                onClick={() =>
                                                    setFieldValue("email", `${values.email}${emailEnding}`, true)
                                                }
                                            >
                                                {emailEnding}
                                            </Button>
                                        ))}
                                    </Flex>
                                </ModalBody>
                                <ModalActions>
                                    <Stack isInline stretch={4} justify="center">
                                        <Button
                                            type="submit"
                                            size="lg"
                                            flex="1"
                                            themeColor="green"
                                            isDisabled={!isValid || !values.email}
                                            isLoading={isSubmitting}
                                        >
                                            {translate("send")}
                                        </Button>
                                        {printerState === PrinterStates.AVAILABLE && (
                                            <Button size="lg" onClick={onChoosePhysicalReceipt} rightIcon={TiPrinter}>
                                                {translate("send")}
                                            </Button>
                                        )}
                                    </Stack>
                                </ModalActions>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};
