import React from "react";

import { Box, Center, Text } from "Atoms";
import { LinkButton } from "Molecules";
import { LanguageHeader } from "Components";
import { useLanguage, useQopla } from "Providers";
import { isAtLeastAdmin } from "Utils";

type Props = {};

export const PosHasNoMenusAlert: React.FC<Props> = ({}) => {
    const { translate } = useLanguage();
    const { authenticatedUser } = useQopla();

    const _isAtLeastAdmin = isAtLeastAdmin(authenticatedUser?.roles ?? []);

    return (
        <Center direction="column" maxW="1000px" margin="0 auto" align="flex-start" alignItems="flex-start">
            <Box display="flex" flexDirection="column">
                <Box>
                    <LanguageHeader tid="posIsMissingMenusTitle" as="h1" size="3xl" mb={3} />
                    <Text fontSize="xl" mb={6}>
                        {translate(
                            _isAtLeastAdmin
                                ? "posIsMissingMenusDescriptionForAdmins"
                                : "posIsMissingMenusDescriptionForNonAdmins"
                        )}
                    </Text>
                </Box>
                {_isAtLeastAdmin && (
                    <LinkButton to="/admin/posAdmin" mt={8} fullWidth size="xl" rounded="lg">
                        {translate("goToMenus")}
                    </LinkButton>
                )}
            </Box>
        </Center>
    );
};
