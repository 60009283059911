import React from "react";

import { TabList, TabPanels, Tabs } from "Organisms";
import { generateMenuPanes } from "../utils/generatePanesHelpers";
import { PosMenuContent } from "./PosMenuContent";
import { Menu } from "Types";

type Props = {
    activePane: number;
    setActivePane: (index: number) => void;
    menus: Menu[];
};

export const PosMenuTabs: React.FC<Props> = ({ activePane, setActivePane, menus }) => {
    const menuPanes = generateMenuPanes(menus, PosMenuContent, activePane);

    return (
        <Tabs
            index={activePane}
            onChange={index => setActivePane(index)}
            h="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            size="lg"
            isLazy
        >
            <TabList h="auto">{menuPanes.map(pane => pane.tab)}</TabList>
            <TabPanels flex="1" overflow="auto">
                {menuPanes.map(pane => pane.tabPanel)}
            </TabPanels>
        </Tabs>
    );
};
