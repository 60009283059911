import React from "react";

import { ContactPerson, InvoiceCustomer } from "Types";
import { Label, NewGrid as Grid, Input, LabelProps, Flex } from "Atoms";

type Props = {
    selectedInvoiceCustomer: InvoiceCustomer;
    selectedContactPerson: ContactPerson;
    setInvoiceComment: React.Dispatch<React.SetStateAction<string | null>>;
};

const DetailsLabel: React.FC<LabelProps> = ({ ...props }) => (
    <Label background="white" fontSize="xl" mt={4} mb={4} {...props} />
);

export const PosInvoiceContactDetails: React.FC<Props> = ({
    selectedContactPerson,
    selectedInvoiceCustomer,
    setInvoiceComment
}) => {
    const { invoiceAddress, organisationNumber } = selectedInvoiceCustomer;

    const onCommentInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceComment(event.target.value);
    };

    const hasCustomerNumber = !!selectedInvoiceCustomer.customerNumber;

    return (
        <>
            <Flex direction="column">
                <DetailsLabel fontWeight="bold">Företag:</DetailsLabel>
                <DetailsLabel fontWeight="bold">Organisationsnummer:</DetailsLabel>
                <DetailsLabel fontWeight="bold">Adress:</DetailsLabel>
                <DetailsLabel fontWeight="bold">Postkod:</DetailsLabel>
                <DetailsLabel fontWeight="bold">Kontaktperson:</DetailsLabel>
                <DetailsLabel fontWeight="bold">Personnummer:</DetailsLabel>
                <DetailsLabel fontWeight="bold">Tel nr:</DetailsLabel>
                {hasCustomerNumber && <DetailsLabel fontWeight="bold">Kund nr:</DetailsLabel>}
                <DetailsLabel fontWeight="bold">Fakturakommentar:</DetailsLabel>
            </Flex>
            <Flex direction="column">
                <DetailsLabel>{invoiceAddress.name}</DetailsLabel>
                <DetailsLabel>{organisationNumber}</DetailsLabel>
                <DetailsLabel>{invoiceAddress.addressLine}</DetailsLabel>
                <DetailsLabel>
                    {invoiceAddress.postCode}
                    {invoiceAddress.city}
                </DetailsLabel>
                <DetailsLabel>{selectedContactPerson.name}</DetailsLabel>
                <DetailsLabel>{selectedContactPerson.personalNumber}</DetailsLabel>
                <DetailsLabel>{selectedContactPerson.phoneNumber}</DetailsLabel>
                {hasCustomerNumber && <DetailsLabel>{selectedInvoiceCustomer.customerNumber}</DetailsLabel>}
                <Input size="lg" onChange={onCommentInputChange} placeholder="Lägg till kommentar" />
            </Flex>
        </>
    );
};
