import React from "react";

import { SpringButton } from "TempUtils";
import { Flex, Label, NewDivider, Text, Box } from "Atoms";
import { shouldDisplayStockStatusText } from "Utils";

type Props = {
    product: any;
    priceText: string;
    productHandler: Function;
    customColor?: string;
    selectedQuantity?: number;
    isDisabled: boolean;
    remainingStock: number | undefined;
    isOutOfStock: boolean;
};

export const PosProduct: React.FC<Props> = ({
    product,
    priceText,
    productHandler,
    customColor,
    selectedQuantity = 0,
    isDisabled,
    remainingStock,
    isOutOfStock
}) => {
    let colors: any = {};
    if (selectedQuantity > 0) {
        colors = {
            backgroundColor: "#8bca9d",
            borderColor: "#8bca9d",
            nameColor: "white",
            dividerColor: "green.700",
            priceColor: "white"
        };
    } else {
        colors = {
            backgroundColor: customColor ? customColor : "white",
            borderColor: "gray.300",
            nameColor: "gray.900",
            dividerColor: "gray.500",
            priceColor: "gray.800"
        };
    }

    const { nameColor, dividerColor, priceColor, ...buttonColors } = colors;

    const _shouldDisplayStockStatusText = shouldDisplayStockStatusText(remainingStock);

    return (
        <SpringButton
            isDisabled={isDisabled}
            customOnClick={productHandler}
            {...buttonColors}
            whiteSpace="normal"
            h="100%"
            w="100%"
            rounded="lg"
            borderWidth="2px"
            borderStyle="solid"
            boxShadow="md"
            position="relative"
            _hover={undefined}
            _disabled={undefined}
        >
            {isOutOfStock && (
                <Label
                    themeColor="red"
                    fontSize="md"
                    position="absolute"
                    top="5px"
                    left="-10px"
                    transform="rotate(-35deg)"
                    fontWeight="700"
                >
                    SLUT
                </Label>
            )}
            {selectedQuantity > 0 && (
                <Label
                    position="absolute"
                    left="5px"
                    top="5px"
                    justifyContent="center"
                    backgroundColor="rgb(237, 242, 247)"
                >
                    {selectedQuantity}
                </Label>
            )}
            {_shouldDisplayStockStatusText && (
                <Text position="absolute" right="10px" top="5px" fontSize="sm" color="gray.600">
                    Fåtal kvar
                </Text>
            )}
            <Flex direction="column" justify="center" align="center" p={2} minHeight="10rem" w="100%">
                <Flex flex="1" align="center">
                    <Text fontSize="xl" color={nameColor} fontWeight="bold">
                        {product.name}
                    </Text>
                </Flex>
                {priceText && (
                    <Box w="100%">
                        <NewDivider color={dividerColor} mb={4} />
                        {priceText && (
                            <Text as="span" fontSize="xl" color={priceColor}>
                                {priceText}
                            </Text>
                        )}
                    </Box>
                )}
            </Flex>
        </SpringButton>
    );
};
