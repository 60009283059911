import React, { useEffect } from "react";
import { IconType } from "@react-icons/all-files/lib";
import { FcLock } from "@react-icons/all-files/fc/FcLock";
import { FaParking } from "@react-icons/all-files/fa/FaParking";
import { TiPrinter } from "@react-icons/all-files/ti/TiPrinter";
import Truncate from "react-truncate";

import {
    useQoplaStore,
    usePosStore,
    orderWindowsStore,
    useOrderWindowsStore,
    useTableStore,
    useModalStore
} from "Stores";
import { ButtonProps, Box, Flex, Stack, Text } from "Atoms";
import { PaymentMethod, modalNames, paymentTabStatusTypes, defaultValues } from "Constants";
import {
    useLocalPosSettings,
    useModal,
    usePos,
    useSplitCashRegister,
    getExtendedOrderProducts,
    useLanguage
} from "Providers";
import { getCartTotalPrice, isCartFinished, isPosManualCardButtonLocked } from "Utils";
import { useImperativeQuery, useMothershipMutation } from "Hooks";
import { GET_ORDER_WINDOW_FOR_SHOP } from "GraphQLQueries";
import { UPSERT_ORDER_WINDOW } from "GraphQLMutations";
import { newMothershipApolloClient } from "../../../../../../graphql/clients";
import { OrderWindow, Terminal } from "Types";
import { advanceReceipt } from "../../../../../../admin/utils/AdvanceReceipt";
import { getPosPaymentButtonRows } from "./posPaymentButtonsUtils";
import { PosPaymentButtonsRow } from "./components";
import { useAwaitConfirmAccessCodeModal } from "../PosUnlockLockModal";

export type PosPaymentButton = {
    method: PaymentMethod;
    children: any;
    isDisabled?: boolean;
    isLocked?: boolean;
    leftIcon?: IconType | undefined;
    onClick: ButtonProps["onClick"];
    style: any;
};

const baseProps = {
    height: 70,
    fontSize: "1.5rem",
    borderRadius: 0
};

const paymentButtonStyles = {
    GREEN: {
        ...baseProps,
        backgroundColor: "#8bca9d",
        color: "white",
        width: "100%",
        flex: "1"
    },
    TRANSPARENT: {
        ...baseProps,
        backgroundColor: "transparent",
        flex: "1",
        border: "1px",
        borderStyle: "solid",
        borderColor: "#E2E8F0"
    }
};

const hiddenPaymentMethodsOnLock = [
    PaymentMethod.SWISH,
    PaymentMethod.POSTPONED,
    PaymentMethod.ADVANCE_RECEIPT,
    PaymentMethod.MANUAL_CARD,
    PaymentMethod.CASH,
    PaymentMethod.INVOICE
];

type Props = {
    handlePayment: Function;
    setOpenPosTerminalsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PosPaymentButtons: React.FC<Props> = ({ handlePayment, setOpenPosTerminalsModal }) => {
    const activeOrderWindow = orderWindowsStore(state => state.getActiveOrderWindow());
    const cartProducts = activeOrderWindow?.cartProducts;

    const { translate } = useLanguage();
    const { openModal } = useModal();
    const { updateActivePaymentTabStatus } = usePosStore();
    const { selectedShop } = useQoplaStore();
    const { updateOrderWindow, addCartToDTPreviousCart } = useOrderWindowsStore();
    const { selectedPos, activeTerminalIds, setActiveTerminalIds, posTerminals, queueHasStarted } = usePosStore();
    const { handleSetActiveOrderWindowId, activePaymentTabs } = usePos();
    const { isInSplitMode, splitCartProducts } = useSplitCashRegister();
    const { isTableOpen } = useTableStore();
    const verifyAccessCode = useAwaitConfirmAccessCodeModal();

    const {
        posSettings: { enableManualCardPayment, lockableParts },
        disableOnLockingPos: isCustomerFacingEnabled
    } = useLocalPosSettings();

    useEffect(() => {
        if (activePaymentTab && activePaymentTab.terminalId && activePaymentTab.paymentMethod === PaymentMethod.CARD) {
            setActiveTerminalIds(Array.from(new Set([...activeTerminalIds, activePaymentTab.terminalId])));
        }
    }, [activePaymentTabs]);

    const fetchShopsOrderWindows = useImperativeQuery(GET_ORDER_WINDOW_FOR_SHOP, {
        client: newMothershipApolloClient,
        variables: { shopId: selectedShop?.id }
    });
    const [upsertOrderWindowMutation] = useMothershipMutation(UPSERT_ORDER_WINDOW);

    const receiptPrinter = selectedPos?.receiptPrinter || defaultValues.selectedPos.receiptPrinter;

    const activePaymentTab = activeOrderWindow && activePaymentTabs[activeOrderWindow.id];

    const isDriveThroughEnabled = selectedPos?.postponePaymentEnabled || false;
    const isDriveThroughOrder = isDriveThroughEnabled && activePaymentTab && activePaymentTab.driveThrough;

    const isPostponedOrder = !!activeOrderWindow?.postponePayment;
    const isTableOrder = !!activeOrderWindow?.tableId;
    const canExtendOrder = isPostponedOrder || isDriveThroughOrder;

    const isActivePaymentTabDTOngoing =
        !!activePaymentTab && activePaymentTab?.status === paymentTabStatusTypes.DT_ONGOING;

    const isCartEmpty = cartProducts?.length === 0;
    const isCartNotFinished = !isCartEmpty ? !isCartFinished(cartProducts) : true;

    const hasNewCartProducts = activeOrderWindow && getExtendedOrderProducts(activeOrderWindow).length > 0;
    const hasExtendedOrder = canExtendOrder && hasNewCartProducts;

    const hasCompletedSplitPayment =
        isInSplitMode && splitCartProducts.toPay.length === 0 && splitCartProducts.available.length === 0;

    const isAllPaymentButtonsDisabled =
        isCartNotFinished ||
        !!(!!activePaymentTab && !isActivePaymentTabDTOngoing) ||
        hasCompletedSplitPayment ||
        !!hasExtendedOrder;

    const handleMakePayment = (
        paymentMethod: string,
        extendedProducts?: any,
        overriddenTerminalId?: string | null,
        contactInfo?: any,
        manualCard?: boolean
    ) => {
        const amount = getCartTotalPrice(cartProducts);
        return handlePayment(
            {
                paymentMethod,
                amount
            },
            extendedProducts,
            overriddenTerminalId,
            contactInfo,
            manualCard,
            activeOrderWindow
        );
    };

    const handlePutBackPostponedOrder = async () => {
        if (activeOrderWindow && isPostponedOrder) {
            const fetchedOrderWindows = await fetchShopsOrderWindows();
            const orderWindowToRestore = fetchedOrderWindows.data.getOrderWindowsForShop.find(
                (orderWindow: OrderWindow) => orderWindow.id === activeOrderWindow.id
            );

            if (!!orderWindowToRestore) {
                orderWindowToRestore.shouldHide = true;
                updateOrderWindow(orderWindowToRestore);
                handleSetActiveOrderWindowId(null);
                const shouldSaveOrderWindowToDB = !isDriveThroughEnabled;
                if (shouldSaveOrderWindowToDB) {
                    await upsertOrderWindowMutation({
                        variables: { orderWindow: orderWindowToRestore }
                    });
                }
            }
        } else {
            openModal(modalNames.PHONE_ORDER_MODAL, {
                makePostponedPayment: handleMakePayment
            });
        }
    };

    const handleManualButton = async () => {
        const manualCardIsLocked = isPosManualCardButtonLocked(lockableParts);

        if (manualCardIsLocked) {
            const accessGranted = await verifyAccessCode();
            if (accessGranted) {
                handleMakePayment(PaymentMethod.CARD, null, null, null, true);
            }
        } else {
            handleMakePayment(PaymentMethod.CARD, null, null, null, true);
        }
    };

    const printAdvanceReceipt = () => {
        if (activeOrderWindow && receiptPrinter && selectedShop) {
            advanceReceipt(receiptPrinter, activeOrderWindow, selectedShop);
        }
    };

    let allowedPaymentMethods = selectedShop?.settings?.allowedPaymentMethods;
    const allowPhonePostponeOrders = selectedPos?.allowPhonePostponeOrders || false;

    let paymentButtons: PosPaymentButton[] = [];

    const getCardPaymentButton = (posTerminal: Terminal): PosPaymentButton => {
        const cardButtonTid = isCustomerFacingEnabled ? "pay" : "card";
        return {
            method: PaymentMethod.CARD,
            children: (
                <Stack stretch={2} align="center" justify="center">
                    <Text>{translate(cardButtonTid)}</Text>
                    <Text as="span" fontSize="sm">
                        {/** @ts-ignore */}
                        <Truncate width={150}>{posTerminal.description}</Truncate>
                    </Text>
                </Stack>
            ),
            isDisabled: activeTerminalIds.includes(posTerminal.terminalId),
            onClick: () => handleMakePayment(PaymentMethod.CARD, null, posTerminal.terminalId),
            style: paymentButtonStyles.GREEN
        };
    };

    const extendPaymentButton: PosPaymentButton = {
        method: PaymentMethod.POSTPONED_EXTENDED,
        children: <Text>{translate(hasNewCartProducts ? "extend" : "save")}</Text>,
        onClick: async () => {
            const extendedProducts = getExtendedOrderProducts(activeOrderWindow as OrderWindow);
            const shouldSaveOrderWindowToDB = !isDriveThroughEnabled;

            if (!!extendedProducts?.length) {
                handleMakePayment(PaymentMethod.POSTPONED_EXTENDED, extendedProducts);
            } else {
                if (shouldSaveOrderWindowToDB) {
                    await upsertOrderWindowMutation({
                        variables: { orderWindow: activeOrderWindow }
                    });
                }
                updateActivePaymentTabStatus(
                    activeOrderWindow?.id as string,
                    true,
                    "Sparat",
                    "",
                    paymentTabStatusTypes.POSTPONED_EXTENDED
                );
                addCartToDTPreviousCart(activeOrderWindow?.id);
            }
        },
        style: paymentButtonStyles.TRANSPARENT
    };

    const postponedPaymentButton: PosPaymentButton = {
        method: PaymentMethod.POSTPONED,
        children: isPostponedOrder ? <Text>{translate("putBack")}</Text> : <Box size="24px" as={FaParking} />,
        isDisabled: isInSplitMode || queueHasStarted,
        onClick: () => handlePutBackPostponedOrder(),
        style: paymentButtonStyles.TRANSPARENT
    };

    const isManualCardLocked = isPosManualCardButtonLocked(lockableParts);

    const enableManualCartPaymentButton: PosPaymentButton = {
        method: PaymentMethod.MANUAL_CARD,
        children: <Text>{translate("manualCard")}</Text>,
        isLocked: isManualCardLocked,
        leftIcon: isManualCardLocked ? FcLock : undefined,
        onClick: () => handleManualButton(),
        style: paymentButtonStyles.GREEN
    };

    const advanceReceiptPaymentButton: PosPaymentButton = {
        method: PaymentMethod.ADVANCE_RECEIPT,
        children: <Box size="24px" as={TiPrinter} />,
        onClick: printAdvanceReceipt,
        style: paymentButtonStyles.TRANSPARENT
    };

    const swishPaymentButton: PosPaymentButton = {
        method: PaymentMethod.SWISH,
        children: <Text>Swish</Text>,
        onClick: () => handleMakePayment(PaymentMethod.SWISH),
        style: paymentButtonStyles.TRANSPARENT
    };

    const cashPaymentButton: PosPaymentButton = {
        method: PaymentMethod.CASH,
        children: <Text>{translate("cash")}</Text>,
        onClick: () => handleMakePayment(PaymentMethod.CASH),
        style: paymentButtonStyles.TRANSPARENT
    };

    const invoicePaymentButton: PosPaymentButton = {
        method: PaymentMethod.INVOICE,
        children: <Text>{translate("invoice")}</Text>,
        onClick: () => handleMakePayment(PaymentMethod.INVOICE),
        style: paymentButtonStyles.TRANSPARENT
    };

    const driveThroughAddPaymentButton: PosPaymentButton = {
        method: PaymentMethod.DTADDORDER,
        children: <Text>{translate("addOrder")}</Text>,
        isDisabled: false,
        onClick: () => handleMakePayment(PaymentMethod.DTADDORDER, null, ""),
        style: paymentButtonStyles.GREEN
    };

    const shouldDisplayDTAddOrderButton = isDriveThroughEnabled && !activePaymentTab;
    const showExtendButton = canExtendOrder && !isTableOrder;

    const shouldDisplayAdvanceReceiptButton = isPostponedOrder || isTableOpen;

    if (!shouldDisplayDTAddOrderButton) {
        if (allowedPaymentMethods?.includes(PaymentMethod.CARD)) {
            const cardButtonTid = isCustomerFacingEnabled ? "pay" : "card";
            const shouldHaveMaxTwoCardButtons = posTerminals.length === 2;
            const shouldDisplayCardModalButton = posTerminals.length > 2;

            /**
             * We allow a maximum of two card buttons.
             * If POS has more than two terminals connected, we open a terminal selection modal
             * We fallback to one card button.
             */
            if (!!posTerminals.length) {
                if (shouldHaveMaxTwoCardButtons) {
                    paymentButtons.push(getCardPaymentButton(posTerminals[0]));
                    paymentButtons.push(getCardPaymentButton(posTerminals[1]));
                } else if (shouldDisplayCardModalButton) {
                    paymentButtons.push({
                        method: PaymentMethod.CARD,
                        children: <Text>{translate(cardButtonTid)}</Text>,
                        onClick: () => setOpenPosTerminalsModal((isOpen: boolean) => !isOpen),
                        style: paymentButtonStyles.GREEN
                    });
                } else {
                    paymentButtons.push({
                        method: PaymentMethod.CARD,
                        children: <Text>{translate(cardButtonTid)}</Text>,
                        onClick: () => handleMakePayment(PaymentMethod.CARD),
                        style: paymentButtonStyles.GREEN
                    });
                }
            }
        }

        if (enableManualCardPayment || allowedPaymentMethods?.includes(PaymentMethod.MANUAL_CARD)) {
            paymentButtons.push(enableManualCartPaymentButton);
        }

        if (allowedPaymentMethods?.includes(PaymentMethod.SWISH)) {
            paymentButtons.push(swishPaymentButton);
        }

        if (allowedPaymentMethods?.includes(PaymentMethod.CASH)) {
            paymentButtons.push(cashPaymentButton);
        }

        if (allowedPaymentMethods?.includes(PaymentMethod.INVOICE)) {
            paymentButtons.push(invoicePaymentButton);
        }

        if (showExtendButton) {
            paymentButtons.push(extendPaymentButton);
        }

        if (allowPhonePostponeOrders && !isTableOpen) {
            paymentButtons.push(postponedPaymentButton);
        }

        if (shouldDisplayAdvanceReceiptButton) {
            paymentButtons.push(advanceReceiptPaymentButton);
        }
    } else {
        paymentButtons.push(driveThroughAddPaymentButton);
    }

    /**
     * Note: getPosPaymentButtonRows does not change the contents/functionality of the paymentButtons, only determines
     * which row they should appear in the UI when rendered
     *
     * @argument paymentButtons - an array of PosPaymentButtons in the order to be shown, left to right, top to bottom
     * @returns paymentButtonsRows - an array of arrays containing the layout order of the paymentButtons
     */

    const _paymentButtons = isCustomerFacingEnabled
        ? paymentButtons.filter(paymentButton => !hiddenPaymentMethodsOnLock.includes(paymentButton.method))
        : paymentButtons;
    const paymentButtonRows = getPosPaymentButtonRows(_paymentButtons);

    const paymentButtonRowProps = {
        isAllDisabled: isAllPaymentButtonsDisabled,
        shouldEnablePostponed: isPostponedOrder && !activePaymentTab,
        shouldEnablePostponedExtended: !!hasNewCartProducts
    };

    return (
        <Flex h="auto" direction="column">
            <PosPaymentButtonsRow row={paymentButtonRows.top} {...paymentButtonRowProps} />
            <PosPaymentButtonsRow row={paymentButtonRows.middle} {...paymentButtonRowProps} />
            <PosPaymentButtonsRow row={paymentButtonRows.bottom} {...paymentButtonRowProps} />
        </Flex>
    );
};
