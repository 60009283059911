import { PaymentMethod } from "Constants";
import { PosPaymentButton } from "./PosPaymentButtons";

type PosPaymentButtonsLayout = {
    top: PosPaymentButton[];
    middle: PosPaymentButton[];
    bottom: PosPaymentButton[];
};

const PosPaymentButtonLayoutOrder = {
    top: new Set([PaymentMethod.CARD, PaymentMethod.DTADDORDER, PaymentMethod.MANUAL_CARD]),
    middle: new Set([PaymentMethod.SWISH, PaymentMethod.CASH, PaymentMethod.INVOICE]),
    bottom: new Set([PaymentMethod.POSTPONED_EXTENDED, PaymentMethod.POSTPONED, PaymentMethod.ADVANCE_RECEIPT])
};

export const getPosPaymentButtonRows = (posPaymentButtons: PosPaymentButton[]) => {
    /*
        When the 'P' button shows, we want to concat it to the second row.
        When we have an open parked order we want to show 'Save' and 'Put back' on its own row
        to save space.
    */
    const postPonedButtonsLength = posPaymentButtons.filter(buttons =>
        PosPaymentButtonLayoutOrder.bottom.has(buttons.method)
    ).length;
    const shouldInsertThirdRow = postPonedButtonsLength > 1;
    const shouldAddParkButtonToSecondRow = postPonedButtonsLength === 1;

    if (shouldAddParkButtonToSecondRow) {
        PosPaymentButtonLayoutOrder.bottom.forEach(
            PosPaymentButtonLayoutOrder.middle.add,
            PosPaymentButtonLayoutOrder.middle
        );
    } else {
        PosPaymentButtonLayoutOrder.bottom.forEach(
            PosPaymentButtonLayoutOrder.middle.delete,
            PosPaymentButtonLayoutOrder.middle
        );
    }

    const getPosPaymentButtonIndex = (paymentMethodRows: Set<PaymentMethod>, posPaymentButton: PosPaymentButton) => {
        return Array.from(paymentMethodRows).findIndex(button => button === posPaymentButton.method);
    };

    return posPaymentButtons?.reduce<PosPaymentButtonsLayout>(
        (acc, next) => {
            const { top, middle, bottom } = acc;

            if (PosPaymentButtonLayoutOrder.top.has(next.method)) {
                const index = getPosPaymentButtonIndex(PosPaymentButtonLayoutOrder.top, next);
                top.splice(index, 0, next);
            }

            if (shouldInsertThirdRow) {
                if (PosPaymentButtonLayoutOrder.middle.has(next.method)) {
                    const index = getPosPaymentButtonIndex(PosPaymentButtonLayoutOrder.middle, next);
                    middle.splice(index, 0, next);
                }

                if (PosPaymentButtonLayoutOrder.bottom.has(next.method)) {
                    const index = getPosPaymentButtonIndex(PosPaymentButtonLayoutOrder.bottom, next);
                    bottom.splice(index, 0, next);
                }
            } else {
                if (PosPaymentButtonLayoutOrder.middle.has(next.method)) {
                    const index = getPosPaymentButtonIndex(PosPaymentButtonLayoutOrder.middle, next);
                    middle.splice(index, 0, next);
                }
            }

            return acc;
        },
        { top: [], middle: [], bottom: [] }
    );
};
