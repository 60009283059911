import React, { useEffect, useState } from "react";

import { useActiveMenuProductCategories } from "Hooks";
import { useOrderWindowsStore, usePosStore } from "Stores";
import { Box, Stack } from "Atoms";
import { ProductsSearchInput } from "Molecules";
import { PosMenuCategories, PosProductGrid } from "./";
import { Menu, MenuProductCategory } from "Types";
import { BundleCreator } from "./BundleCreator";
import { getMenuProductCategoryFromSearchedProducts } from "../utils";

type Props = {
    menu: Menu;
    activePane: number;
};

export const PosMenuContent: React.FC<Props> = ({ menu, activePane }) => {
    const [productTextSearchValue, setProductTextSearchValue] = useState("");

    const { activeCategory, setActiveCategory, selectedBundleCartProduct } = useOrderWindowsStore();
    const { hideFromStockIds } = usePosStore();

    const posFavorite = menu.menuProductCategories.find(cat => cat.posFavorite === true);
    const defaultCategory = posFavorite ? posFavorite : menu.menuProductCategories[0];

    // When changing the menu we want to
    // reset the active menucategory to its
    // default value
    useEffect(() => {
        setActiveCategory(defaultCategory);
    }, [activePane]);

    const menuProductCategories = useActiveMenuProductCategories(menu, hideFromStockIds);

    const hasSelectedBundleCartProduct = !!selectedBundleCartProduct;
    const hasProductTextSearchValue = !!productTextSearchValue;
    const hasActiveCategory = !!activeCategory;

    const menuProductCategory = hasProductTextSearchValue
        ? getMenuProductCategoryFromSearchedProducts(menuProductCategories, productTextSearchValue)
        : hasActiveCategory
        ? activeCategory
        : defaultCategory;

    return (
        <Box h="100%" overflow="auto" display="flex" flexDirection="column">
            <ProductsSearchInput
                value={productTextSearchValue}
                setValue={setProductTextSearchValue}
                inputSize="lg"
                inputRounded="0px"
            />
            <Stack isInline stretch={0} borderRight="1px solid #e2e2e2" overflow="auto">
                <Box backgroundColor="#f0f0f0" p={4} w="14rem" overflow="auto">
                    {!productTextSearchValue && activeCategory && (
                        <PosMenuCategories
                            menuProductCategories={menuProductCategories}
                            handleActiveCategory={setActiveCategory}
                            activeCategoryId={activeCategory.id}
                        />
                    )}
                </Box>
                <Box flex="1" overflowY="auto" overflowX="hidden">
                    {hasSelectedBundleCartProduct ? (
                        <BundleCreator selectedBundleCartProduct={selectedBundleCartProduct} />
                    ) : (
                        <PosProductGrid menuProductCategory={menuProductCategory as MenuProductCategory} />
                    )}
                </Box>
            </Stack>
        </Box>
    );
};
