import React from "react";

import { Modal, ModalBody, ModalHeader } from "Molecules";
import { Header, Flex } from "Atoms";
import { modalNames } from "Constants";
import { useQopla, IModalContext } from "Providers";
import { PhoneOrderForm } from "./components/PhoneOrderForm";
import { PosModalCloseBtn } from "../shared";

type Props = {
    makePostponedPayment: Function;
    activeHours: any;
};

export const PhoneOrderModal: React.FC<IModalContext<Props>> = ({
    closeModal,
    modalContent: { makePostponedPayment }
}) => {
    const closePhoneOrderModal = () => closeModal(modalNames.PHONE_ORDER_MODAL);

    return (
        <Modal id="posPhoneOrderModal" open={true}>
            <ModalHeader>
                <Flex align="center" justify="space-between">
                    <Header as="h1" size="xl">
                        Parkera en order
                    </Header>
                    <PosModalCloseBtn closeModal={closePhoneOrderModal} />
                </Flex>
            </ModalHeader>
            <ModalBody>
                <PhoneOrderForm makePostponedPayment={makePostponedPayment} closeModal={closePhoneOrderModal} />
            </ModalBody>
        </Modal>
    );
};
