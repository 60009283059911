import React from "react";

import { Flex } from "Atoms";
import { PaymentMethod } from "Constants";
import { PosPaymentButton } from "./";
import { PosPaymentButton as PosPaymentButtonType } from "../PosPaymentButtons";

type Props = {
    row: PosPaymentButtonType[];
    isAllDisabled: boolean;
    shouldEnablePostponed: boolean;
    shouldEnablePostponedExtended: boolean;
};

export const PosPaymentButtonsRow: React.FC<Props> = ({
    row,
    isAllDisabled,
    shouldEnablePostponed,
    shouldEnablePostponedExtended
}) => {
    return (
        <Flex>
            {row.map((paymentButton, paymentButtonIndex) => {
                const paymentButtonKey = `${paymentButton.method}-${paymentButtonIndex}`;

                const isPostponedExtended = paymentButton.method === PaymentMethod.POSTPONED_EXTENDED;
                const isPostponed = paymentButton.method === PaymentMethod.POSTPONED;

                let isDisabled = paymentButton.isDisabled || isAllDisabled;

                if (isPostponedExtended && shouldEnablePostponedExtended) {
                    isDisabled = false;
                }

                if (isPostponed && shouldEnablePostponed) {
                    isDisabled = false;
                }

                return <PosPaymentButton key={paymentButtonKey} {...paymentButton} isDisabled={isDisabled} />;
            })}
        </Flex>
    );
};
