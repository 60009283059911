import React, { useMemo } from "react";
import { useMedia } from "react-media";
import { useTable, usePagination, useFlexLayout, useFilters, Row, HeaderGroup, Cell } from "react-table-next";
import { BsChevronDoubleRight } from "@react-icons/all-files/bs/BsChevronDoubleRight";
import { BsChevronDoubleLeft } from "@react-icons/all-files/bs/BsChevronDoubleLeft";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack";

import { IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "Molecules";
import { Box, Flex, Input, Stack, Text } from "Atoms";
import { ContactPerson, InvoiceCustomer } from "Types";
import { GLOBAL_BREAK_POINTS } from "Constants";

type ExtendedHeaderGroup = {
    filterable?: boolean;
} & HeaderGroup<InvoiceCustomer>;

type Props = {
    invoiceCustomers?: InvoiceCustomer[];
    selectedInvoiceCustomer: InvoiceCustomer | null;
    setSelectedInvoiceCustomer: (customer: InvoiceCustomer | null) => void;
    setSelectedContactPerson: (person: ContactPerson | null) => void;
};

export const DefaultColumnFilter = ({
    column: { filterValue, setFilter }
}: {
    column: {
        filterValue: string;
        setFilter: (value: string | undefined) => void;
    };
}) => {
    return (
        <Input
            value={filterValue || ""}
            onChange={(e: any) => {
                setFilter(e.target.value || undefined);
            }}
            size="md"
            width="98%"
            color="gray.700"
            placeholder={`Sök...`}
        />
    );
};

export const defaultFilterMethod = (rows: Row[], id: string, filterValue: string) => {
    return rows.filter((row: any) =>
        row.values[id] ? row.values[id].toLowerCase().includes(filterValue.toLowerCase()) : false
    );
};

export const PosInvoiceCustomerTable: React.FC<Props> = ({
    invoiceCustomers,
    selectedInvoiceCustomer,
    setSelectedContactPerson,
    setSelectedInvoiceCustomer
}) => {
    const isSmallerScreen = useMedia({ query: GLOBAL_BREAK_POINTS.LG });

    const data = !!invoiceCustomers ? invoiceCustomers : ([] as InvoiceCustomer[]);

    const hasCustomerNumber = invoiceCustomers?.some(invoiceCustomer => !!invoiceCustomer.customerNumber);

    const columns = useMemo(
        () => [
            {
                Header: "Företag",
                accessor: "invoiceAddress.name",
                Filter: DefaultColumnFilter,
                filterMethod: defaultFilterMethod,
                filterable: true
            },
            {
                Header: "Org. nr",
                accessor: "organisationNumber",
                Filter: DefaultColumnFilter,
                filterMethod: defaultFilterMethod,
                filterable: true
            },
            {
                Header: "Kund nr",
                accessor: "customerNumber",
                Filter: DefaultColumnFilter,
                filterMethod: defaultFilterMethod,
                filterable: true
            }
        ],
        [hasCustomerNumber]
    );

    //hide customerNumber column, if there are no invoice customers who uses this optional field
    const tableInstance = useTable(
        {
            //@ts-ignore
            columns,
            data,
            initialState: {
                //@ts-ignore
                pageIndex: 0,
                pageSize: 7,
                hiddenColumns: hasCustomerNumber ? [] : ["customerNumber"]
            },
            autoResetFilters: false,
            autoResetPage: false
        },
        useFilters,
        useFlexLayout,
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow } = tableInstance;
    //@ts-ignore
    const { page, gotoPage, nextPage, previousPage, pageOptions, canPreviousPage, canNextPage } = tableInstance;
    const {
        //@ts-ignore
        state: { pageIndex },
        //@ts-ignore
        pageCount
    } = tableInstance;

    const onSelectInvoiceCustomer = (invoiceCustomer: InvoiceCustomer) => {
        setSelectedInvoiceCustomer(invoiceCustomer);
        setSelectedContactPerson(null);
    };

    let padding = {
        ...(!selectedInvoiceCustomer ? { pr: 16, pl: 16 } : { pr: 0, pl: 6 })
    };

    if (isSmallerScreen) {
        padding = {
            pl: 6,
            pr: 0
        };
    }

    return (
        <Flex flex="1" direction="column">
            <Flex flex="1" direction="column" {...padding}>
                <Table
                    {...getTableProps()}
                    wrapperProps={{
                        border: "none",
                        rounded: "none",
                        boxShadow: "none",
                        overflow: "auto"
                    }}
                >
                    <TableHead>
                        {headerGroups.map((headerGroup: ExtendedHeaderGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((header: ExtendedHeaderGroup, index: number) => {
                                    return (
                                        <TableHeader
                                            position="relative"
                                            {...header.getHeaderProps()}
                                            flexDirection="column"
                                            justifyContent="center"
                                            py={2}
                                            px={3}
                                        >
                                            <Box textAlign={"center"}>
                                                {/** @ts-ignore */}
                                                {header.render("Header")}
                                            </Box>
                                            <Box pt={2} w="98%">
                                                {/** @ts-ignore */}
                                                {header.filterable ? header.render("Filter") : null}
                                            </Box>
                                        </TableHeader>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()} width="max-content">
                        {page.map((row: Row<InvoiceCustomer>, index: number) => {
                            prepareRow(row);
                            const customerId = row.original.id;
                            const isRowSelected = selectedInvoiceCustomer?.id === customerId;
                            let rowColour = index % 2 === 0 ? "gray.100" : "white";
                            if (isRowSelected) {
                                rowColour = "blue.200";
                            }
                            return (
                                <React.Fragment key={row.id}>
                                    <TableRow
                                        fontSize="xl"
                                        bg={rowColour}
                                        {...row.getRowProps()}
                                        onClick={() => onSelectInvoiceCustomer(row.original)}
                                    >
                                        {row.cells.map((cell: Cell<InvoiceCustomer>) => {
                                            return (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    overflowX="hidden"
                                                    textOverflow="ellipsis"
                                                >
                                                    {cell.value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                    <TableFooter display="flex" justifyContent="center" alignItems="center">
                        <TableRow>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Flex>
            <Flex flex="1" justifyContent="flex-end" direction="column" alignItems="center" mt={2}>
                <Text
                    as="span"
                    fontSize="lg"
                    letterSpacing="1px"
                    color="gray.600"
                    alignItems="center"
                    fontWeight="bold"
                    pl={3}
                    mb={2}
                >
                    Sida {pageIndex + 1} av {pageOptions.length}
                </Text>

                <Stack isInline stretch={2} alignItems="flex-end" height="auto">
                    <IconButton
                        size="xl"
                        icon={BsChevronDoubleLeft}
                        onClick={() => gotoPage(0)}
                        isDisabled={!canPreviousPage}
                    />
                    <IconButton size="xl" icon={IoIosArrowBack} onClick={previousPage} isDisabled={!canPreviousPage} />
                    <IconButton size="xl" icon={IoIosArrowForward} onClick={nextPage} isDisabled={!canNextPage} />
                    <IconButton
                        size="xl"
                        icon={BsChevronDoubleRight}
                        onClick={() => gotoPage(pageCount - 1)}
                        isDisabled={!canNextPage}
                    />
                </Stack>
            </Flex>
        </Flex>
    );
};
