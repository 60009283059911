import React from "react";
import * as yup from "yup";
import { Formik, Field } from "formik-next";
import moment from "moment";

import { TextareaInput } from "Molecules";
import { FormInput } from "Molecules";
import { NewButton, Flex, Dropdown } from "Atoms";
import { PaymentMethod } from "Constants";
import { formatDateToBackendUTCString } from "../../../../../../../admin/utils/TextFormat";
import { useQoplaStore } from "Stores";
import { gaProductionEvent } from "Utils";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY } from "Types";

type Props = {
    makePostponedPayment: Function;
    closeModal: Function;
};

export const validationSchema = yup.object().shape({
    name: yup.string().required("* Obligatoriskt")
});

const initialValues = { name: "", phoneNumber: "", comment: "", asap: true, pickup: { date: "", time: "" } };

export const PhoneOrderForm: React.FC<Props> = ({ makePostponedPayment, closeModal }) => {
    // const { selectedShop } = useQoplaStore();
    // const activeHours = selectedShop?.activeHours;
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={({ asap, pickup, ...rest }) => {
                let formattedTime = null;
                if (!asap) {
                    const pickupTime = moment(`${pickup.date} ${pickup.time}`);
                    formattedTime = formatDateToBackendUTCString(pickupTime);
                }
                gaProductionEvent({
                    category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_CHECKOUT,
                    action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
                    label: "Add parked order"
                })
                makePostponedPayment(PaymentMethod.POSTPONED, null, null, { ...rest, pickupTime: formattedTime });
                closeModal();
            }}
        >
            {({ handleSubmit, isSubmitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Flex direction="column">
                            <Field
                                name="name"
                                formLabel="Namn"
                                type="text"
                                size="lg"
                                fullWidth
                                isMandatory
                                as={FormInput}
                            />
                            <Field
                                name="phoneNumber"
                                formLabel="Tel"
                                type="number"
                                size="lg"
                                fullWidth
                                as={FormInput}
                            />
                            <Field name="comment" type="text" formLabel="Kommentar" fullWidth as={TextareaInput} />
                            {/* <Flex justify="center" my={4}>
                                <NewButton
                                    size="lg"
                                    type="button"
                                    onClick={() => setFieldValue("asap", true)}
                                    bg={values.asap ? "gray.400" : "gray.300"}
                                >
                                    Snarast
                                </NewButton>
                                <Divider vertical={true} />
                                <NewButton
                                    size="lg"
                                    type="button"
                                    onClick={() => setFieldValue("asap", false)}
                                    bg={!values.asap ? "gray.400" : "gray.300"}
                                >
                                    Ange tid
                                </NewButton>
                            </Flex>
                            {!values.asap && (
                                <Flex mb={12}>
                                    <Dropdown
                                        width="100%"
                                        height="3.5rem"
                                        options={dateStops}
                                        placeholder="Välj en dag"
                                        value={values.pickup.date}
                                        onChange={({ target: { value } }: any) => {
                                            setFieldValue("pickup.date", value);
                                        }}
                                    />
                                    <Dropdown
                                        width="100%"
                                        height="3.5rem"
                                        options={timeStops}
                                        placeholder="Välj en tid"
                                        value={values.pickup.time}
                                        onChange={({ target: { value } }: any) => {
                                            setFieldValue("pickup.time", value);
                                        }}
                                    />
                                </Flex>
                            )}*/}
                            <NewButton size="xl" themeColor="green" type="submit" isDisabled={isSubmitting}>
                                Parkera
                            </NewButton>
                        </Flex>
                    </form>
                );
            }}
        </Formik>
    );
};
