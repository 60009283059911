import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { graphql } from "react-apollo";
import { get } from "lodash";

import { useBaxiSocket, usePos } from "Providers";
import { CANCEL_POS_ORDER } from "GraphQLMutations";
import { confirmNotification, SpringButton } from "TempUtils";
import { Center, Header } from "Atoms";
import { PaymentMethod, paymentTabStatusTypes } from "Constants";
import { getActivePaymentTabIcon, isAlreadyPaid, isCashInvoice } from "../../utils";
import { usePaymentHandler } from "./usePaymentHandler";
import "../../pos.scss";
import { useQopla } from "../../../../../../providers";
import { DimmerContent, Dimmer } from "Molecules";
import { useOrderWindowsStore, usePosStore } from "Stores";

const DISPLAY_CHECK_STATUS_MS = 8000;

const PaymentHandler = ({ children, cancelPosOrder, posTerminals, lastPurchaseCheck }) => {
    const {
        hideOrderWindow,
        paymentTabsUtils: { resetTerminalIdForPaymentTab }
    } = usePos();

    const { activeOrderWindowId, clearOrderWindow } = useOrderWindowsStore();
    const {
        activePaymentTabs,
        activeTerminalIds,
        setActiveTerminalIds,
        getActivePaymentTab,
        updateActivePaymentTabStatus
    } = usePosStore();

    const { terminalMessages, cancelCloudPurchase } = useBaxiSocket();
    const [initialDisableDelay, setInitialDisableDelay] = useState(true);

    const [displayLastPurchaseCheck, setDisplayLastPurchaseCheck] = useState(false);
    const [disableLastPurchaseCheck, setDisableLastPurchaseCheck] = useState(false);
    const lastPurchaseCheckTimer = useRef(null);

    const activePaymentTab = getActivePaymentTab(activeOrderWindowId) || {};
    const cashInvoice = isCashInvoice(activePaymentTab.displayText);
    const shouldDim = !!activePaymentTab.id && activePaymentTab.status !== paymentTabStatusTypes.DT_ONGOING;
    const alreadyPaid = isAlreadyPaid(activePaymentTab.displayHeaderText);

    const { selectedShop } = useQopla();
    const shopHasManualCard = get(selectedShop, "shop.settings.allowedPaymentMethods", []).includes(
        PaymentMethod.MANUAL_CARD
    );
    const statusIconProps = getActivePaymentTabIcon(activePaymentTab.status, cashInvoice, "huge");
    const activePaymentTabHasTerminalMessage = terminalMessages?.get(activePaymentTab.terminalId);
    const isActivePaymentMethodCard = activePaymentTab.paymentMethod === PaymentMethod.CARD;
    const shouldDisplayTerminalMessage = activePaymentTabHasTerminalMessage && isActivePaymentMethodCard;
    const displayAbortCardPurchase =
        PaymentMethod.CARD === activePaymentTab.paymentMethod &&
        activePaymentTab.status === paymentTabStatusTypes.PENDING &&
        !shopHasManualCard;

    useEffect(() => {
        if (!!activePaymentTab.id && activePaymentTab.status === paymentTabStatusTypes.PENDING) {
            setTimeout(() => setInitialDisableDelay(false), 750);
            if (PaymentMethod.CARD === activePaymentTab.paymentMethod) {
                lastPurchaseCheckTimer.current = setTimeout(
                    () => setDisplayLastPurchaseCheck(true),
                    DISPLAY_CHECK_STATUS_MS
                );
            }
        } else if (
            [paymentTabStatusTypes.APPROVED, paymentTabStatusTypes.CANCELLED, paymentTabStatusTypes.FAILED].includes(
                activePaymentTab.status
            )
        ) {
            setInitialDisableDelay(true);
            if (lastPurchaseCheckTimer.current) {
                clearTimeout(lastPurchaseCheckTimer.current);
                setDisplayLastPurchaseCheck(false);
            }
        }
    }, [activePaymentTab]);

    const [disabledCancelBtns, setDisabledCancelBtns, onFinishedApprovedPayment] = usePaymentHandler(
        updateActivePaymentTabStatus,
        clearOrderWindow,
        activePaymentTabs,
        resetTerminalIdForPaymentTab,
        hideOrderWindow
    );

    // We need to wait for a terminal message before being able to click the button
    // Reason being we don't have a message, and clicking the button will override our cancel message
    const terminalMessage = terminalMessages.get(activePaymentTab.terminalId);
    const isCancelButtonInDisabledButtons = disabledCancelBtns.includes(activePaymentTab.terminalId);

    const isCancelButtonDisabled = !terminalMessage || isCancelButtonInDisabledButtons || initialDisableDelay;

    const handleCancelPosOrder = async () => {
        setDisabledCancelBtns(curr => [...curr, activePaymentTab.terminalId]);
        const terminal = posTerminals.find(terminal => terminal.terminalId === activePaymentTab.terminalId);
        if (terminal.cloudEnabled) {
            cancelCloudPurchase(activePaymentTab.terminalId);
        } else {
            try {
                await cancelPosOrder({
                    errorPolicy: "ignore",
                    variables: {
                        terminalId: activePaymentTab.terminalId
                    }
                });
            } catch (err) {
                console.log("err", err);
            }
        }
    };

    const onDismissActivePaymentTab = () => {
        setActiveTerminalIds(activeTerminalIds.filter(terminalId => terminalId !== activePaymentTab?.terminalId));
        onFinishedApprovedPayment(activePaymentTab);
    };

    const checkLastPurchase = async () => {
        setDisableLastPurchaseCheck(true);
        const result = await confirmNotification(
            "Är kunden klar?",
            "Kontrollera så att kunden är klar med sitt köp",
            "Kontrollera Köp",
            "#8bca9d"
        );
        if (result.value) {
            await lastPurchaseCheck();
            setTimeout(() => setDisableLastPurchaseCheck(false), 3000);
        } else {
            setDisableLastPurchaseCheck(false);
        }
    };

    return (
        <Dimmer isActive={shouldDim} h="100%">
            {children({
                activePaymentTabStatus: activePaymentTab.status
            })}
            <DimmerContent bg="whiteAlpha.700">
                <Center direction="column">
                    <Center mb={8} direction="column" h="auto" color="gray.900">
                        <Icon {...statusIconProps} />
                        <Header size="xl" textAlign="center">
                            {activePaymentTab.displayHeaderText}
                        </Header>
                        <Header size="lg" textAlign="center">
                            {activePaymentTab.displayText}
                        </Header>
                        {shouldDisplayTerminalMessage && (
                            <Header size="md" textAlign="center">
                                {terminalMessages.get(activePaymentTab.terminalId)}
                            </Header>
                        )}
                        {cashInvoice && (
                            <SpringButton
                                className="ui button big green-button"
                                customOnClick={onDismissActivePaymentTab}
                            >
                                <span>Ok</span>
                            </SpringButton>
                        )}
                        {alreadyPaid && (
                            <SpringButton className="ui button big negative" customOnClick={onDismissActivePaymentTab}>
                                <span>Stäng nota</span>
                            </SpringButton>
                        )}
                        {displayAbortCardPurchase && (
                            <SpringButton
                                disabled={isCancelButtonDisabled}
                                customOnClick={handleCancelPosOrder}
                                className="ui button"
                                style={{
                                    backgroundColor: "#c91618",
                                    color: "#FFF"
                                }}
                            >
                                <span>Avbryt Köp</span>
                            </SpringButton>
                        )}
                        {displayLastPurchaseCheck && activePaymentTab.status === paymentTabStatusTypes.PENDING && (
                            <SpringButton
                                disabled={disableLastPurchaseCheck}
                                customOnClick={checkLastPurchase}
                                className="ui button"
                                style={{
                                    backgroundColor: "#ff9900",
                                    color: "#FFF",
                                    marginTop: "1em"
                                }}
                            >
                                <span>Har köpet fastnat?</span>
                            </SpringButton>
                        )}
                    </Center>
                </Center>
            </DimmerContent>
        </Dimmer>
    );
};

export default graphql(CANCEL_POS_ORDER, {
    name: "cancelPosOrder"
})(PaymentHandler);

PaymentHandler.propTypes = {
    children: propTypes.func.isRequired,
    orderWindowId: propTypes.string.isRequired
};
