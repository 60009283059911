import React, { useState } from "react";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft";

import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { Button, Flex, Header } from "Atoms";
import { GET_INVOICE_CUSTOMERS_BY_SHOP_ID } from "GraphQLQueries";
import { paymentTabsHeaderTexts, paymentTabStatusTypes } from "Constants";
import { PosInvoiceCustomerTable } from "./PosInvoiceCustomerTable";
import { PosInvoiceContactTable } from "./PosInvoiceContactTable";
import { useTheme } from "Providers";
import { PosInvoiceContactDetails } from "./PosInvoiceContactDetails";
import { useMothershipQuery } from "Hooks";
import { InvoiceCustomer, ContactPerson, InvoiceAddress } from "Types";

type Props = {
    onSubmit: (value: { value: InvoiceValues | null; cancel: boolean }) => void;
    modalContent: ModalContent;
    closeModal: (modalToClose: string) => void;
};

export type InvoiceValues = {
    invoiceAddress: InvoiceAddress;
    organisationNumber: string;
    invoiceReference: string;
    contactName: string;
    customerNumber?: string;
    invoiceComment?: string | null;
};

type ModalContent = {
    orderInput: any;
    updateActivePaymentTabStatus: (
        activeOrderWindowId: string,
        operationSuccess: boolean,
        headerText: string,
        displayText: string,
        newStatus: string
    ) => void;
    activeOrderWindowId: string;
};

type QueryData = {
    getShopInvoiceCustomers: InvoiceCustomer[];
};

const PosInvoiceModal: React.FC<Props> = ({ modalContent, onSubmit }) => {
    const [disableBuyButton, setDisableBuyButton] = useState(false);
    const [selectedInvoiceCustomer, setSelectedInvoiceCustomer] = useState<InvoiceCustomer | null>(null);
    const [selectedContactPerson, setSelectedContactPerson] = useState<ContactPerson | null>(null);
    const [invoiceComment, setInvoiceComment] = useState<string | null>(null);
    const [activePage, setActivePage] = useState(0);
    const { colors } = useTheme();
    const { data: invoiceCustomers, loading } = useMothershipQuery<QueryData>(GET_INVOICE_CUSTOMERS_BY_SHOP_ID, {
        variables: { shopId: modalContent.orderInput.shopId },
        fetchPolicy: "network-only"
    });

    const onCloseModal = () => onSubmit({ value: null, cancel: true });

    const makePurchase = async () => {
        if (!selectedInvoiceCustomer || !selectedContactPerson) return;
        setDisableBuyButton(true);

        onSubmit({
            value: {
                invoiceAddress: selectedInvoiceCustomer.invoiceAddress,
                organisationNumber: selectedInvoiceCustomer.organisationNumber,
                invoiceReference: selectedInvoiceCustomer.invoiceReference,
                contactName: selectedContactPerson.name,
                customerNumber: selectedInvoiceCustomer.customerNumber,
                invoiceComment: invoiceComment
            },
            cancel: false
        });
    };

    const manualCloseModal = () => {
        modalContent.updateActivePaymentTabStatus(
            modalContent.activeOrderWindowId,
            false,
            paymentTabsHeaderTexts.CANCELLED,
            "Avbrutet av kassör",
            paymentTabStatusTypes.CANCELLED
        );
        onCloseModal();
    };

    const showInvoiceDetails = !!selectedContactPerson && !!selectedInvoiceCustomer;

    return (
        <Modal size="full" open placement="center">
            <ModalCloseBtn
                onClick={manualCloseModal}
                size="lg"
                style={{ backgroundColor: colors.red["500"], color: colors.white, borderRadius: "50%" }}
            />
            <ModalHeader>
                <Header fontSize="3xl" margin="0">
                    Fakturaköp
                </Header>
            </ModalHeader>
            <ModalBody overflowX="auto" isScrolling={true} height="100%">
                {activePage === 0 ? (
                    <Flex width="100%">
                        <PosInvoiceCustomerTable
                            invoiceCustomers={invoiceCustomers && invoiceCustomers.getShopInvoiceCustomers}
                            selectedInvoiceCustomer={selectedInvoiceCustomer}
                            setSelectedInvoiceCustomer={setSelectedInvoiceCustomer}
                            setSelectedContactPerson={setSelectedContactPerson}
                        />
                        <PosInvoiceContactTable
                            selectedInvoiceCustomer={selectedInvoiceCustomer}
                            selectedContactPerson={selectedContactPerson}
                            setSelectedContactPerson={setSelectedContactPerson}
                        />
                    </Flex>
                ) : (
                    showInvoiceDetails && (
                        <Flex justifyContent="space-evenly">
                            <PosInvoiceContactDetails
                                selectedInvoiceCustomer={selectedInvoiceCustomer}
                                selectedContactPerson={selectedContactPerson}
                                setInvoiceComment={setInvoiceComment}
                            />
                        </Flex>
                    )
                )}
            </ModalBody>

            <ModalActions>
                <Flex justifyContent="flex-end">
                    {activePage === 0 ? (
                        <Button
                            isDisabled={!selectedInvoiceCustomer || !selectedContactPerson}
                            rightIcon={FaAngleRight}
                            onClick={() => setActivePage(1)}
                            themeColor="green"
                            size="xl"
                        >
                            Gå Vidare
                        </Button>
                    ) : (
                        <>
                            <Button
                                isDisabled={!selectedInvoiceCustomer || !selectedContactPerson}
                                leftIcon={FaAngleLeft}
                                onClick={() => setActivePage(0)}
                                themeColor="orange"
                                size="xl"
                                mr={3}
                            >
                                Gå Tillbaka
                            </Button>
                            <Button isDisabled={disableBuyButton} onClick={makePurchase} themeColor="green" size="xl">
                                Skapa Fakturaköp
                            </Button>
                        </>
                    )}
                </Flex>
            </ModalActions>
        </Modal>
    );
};

export default PosInvoiceModal;
