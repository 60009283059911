import React, { useState } from "react";

import { ButtonProps } from "Atoms";
import { SpringButton } from "TempUtils";

type Props = {
    style: any;
} & ButtonProps;

const DELAY_THRESHOLD_MS = 400;
export const PosPaymentButton: React.FC<Props> = ({ children, onClick, ...rest }) => {
    const [lastClicked, setLastClicked] = useState(0);

    const onClickHandler = (event: any) => {
        const now = new Date().getTime();
        const shouldCancelClick = now - lastClicked < DELAY_THRESHOLD_MS;
        if (shouldCancelClick) {
            return;
        }
        setLastClicked(now);
        onClick && onClick(event);
    };

    return (
        <SpringButton customOnClick={onClickHandler} {...(rest as any)}>
            {children}
        </SpringButton>
    );
};
