import React from "react";

import { useLocalPosSettings, modals, usePos } from "Providers";
import { activeHoursProducts } from "Utils";
import { PosProduct } from "./";
import { getPriceText } from "../utils/priceUtils";
import { paymentTabStatusTypes } from "Constants";
import { modalNames } from "Constants";
import { useOrderWindowsStore, usePosStore, useQoplaStore, useModalStore } from "Stores";
import { MenuBundleProduct, MenuProduct, MenuProductCategory } from "Types";
import { ScaleInputMode } from "./ScaleModal/ScaleModal";
import { NewGrid } from "Atoms";

type Props = {
    menuProductCategory: MenuProductCategory;
};

export const PosProductGrid: React.FC<Props> = ({ menuProductCategory }) => {
    const { selectedShop } = useQoplaStore();
    const { getRemainingStockQuantity, activeOrderWindowId, findMenuProductCategoryForMenuProduct } =
        useOrderWindowsStore();
    const shopId = selectedShop?.id;
    const { setProductAmount, addOrderProductToCart, addOrderProductToCartWithWeight, isRefProductOutOfStock } =
        useOrderWindowsStore();
    const { hideFromStockIds, activePaymentTabs, outOfStockIds } = usePosStore();

    const { disableOnLockingPos } = useLocalPosSettings();
    const { openModal, closeModal } = useModalStore();
    const { productCardAction } = usePos();

    const openModModal = (product: MenuProduct, modificationsToUse: any) => {
        openModal(modals.posModificationsModal, {
            productName: product.refProduct.name,
            modificationsToUse,
            modalCallback: (selectedModifications: any, menuProduct = product) => {
                addOrderProductToCart(
                    menuProduct,
                    shopId as string,
                    menuProductCategory.id,
                    selectedModifications,
                    false,
                    null,
                    [],
                    false,
                    undefined,
                    false
                );
            }
        });
    };

    /**
     * It's possible we need to add the ability to pop the
     * ModModal and ScaleModal in sequence, but not now
     */
    const openScaleModal = (menuProduct: MenuProduct) => {
        openModal(modalNames.SCALE_MODAL, {
            menuProduct,
            onClose: () => {
                closeModal(modalNames.SCALE_MODAL);
            },
            modalCallback: (weight: number, scaleType: ScaleInputMode, menuProduct: MenuProduct) =>
                addOrderProductToCartWithWeight(
                    menuProduct,
                    shopId as string,
                    menuProductCategory.id,
                    weight,
                    scaleType
                ),
            disableManualOnLock: disableOnLockingPos
        });
    };

    const onClickPosProduct = (menuProduct: MenuProduct | MenuBundleProduct) => {
        const foundMenuProductCategory = findMenuProductCategoryForMenuProduct(menuProduct.id);

        const menuProductCategoryId = foundMenuProductCategory?.id ?? menuProductCategory.id;

        if (!menuProductCategoryId) {
            console.warn(`Found no menuProductCategoryId for menuProduct with ID=${menuProduct.id}`);
        }

        productCardAction(
            menuProduct,
            {
                addOrderProductToCart
            },
            shopId as string,
            menuProductCategoryId,
            openModModal,
            false,
            () => { },
            () => openScaleModal(menuProduct as MenuProduct)
        );

        setProductAmount("");
    };

    const allProducts = [...menuProductCategory.menuBundleProducts, ...menuProductCategory.menuProducts].sort(
        (a, b) => a.sortOrder - b.sortOrder
    );

    const orderWindowBusy = !!Object.values(activePaymentTabs).filter(
        paymentTab => paymentTab.status != paymentTabStatusTypes.DT_ONGOING && activeOrderWindowId == paymentTab.id
    ).length;

    return (
        <NewGrid gap={4} p={4} templateColumns="repeat(auto-fill, 150px)" templateRows="repeat(auto-fill, 150px)">
            {activeHoursProducts(allProducts)
                .filter((menuProduct: MenuProduct | MenuBundleProduct) => {
                    const menuProductId =
                        "refBundleProduct" in menuProduct ? menuProduct.refBundleProduct.id : menuProduct.refProduct.id;
                    return hideFromStockIds ? !hideFromStockIds.has(menuProductId) : menuProduct;
                })
                .map((menuProduct: MenuProduct | MenuBundleProduct) => {
                    const refProduct =
                        "refBundleProduct" in menuProduct ? menuProduct.refBundleProduct : menuProduct.refProduct;

                    const priceText = getPriceText(menuProduct);
                    const remainingStock = getRemainingStockQuantity(refProduct.id);
                    const isOutOfStock = isRefProductOutOfStock(refProduct.id, outOfStockIds);

                    return (
                        <PosProduct
                            key={menuProduct.id}
                            priceText={priceText}
                            product={refProduct}
                            customColor={menuProduct.color}
                            isDisabled={isOutOfStock || orderWindowBusy}
                            remainingStock={remainingStock}
                            isOutOfStock={isOutOfStock}
                            productHandler={() => onClickPosProduct(menuProduct)}
                        />
                    );
                })}
        </NewGrid>
    );
};
