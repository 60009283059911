import React, { useMemo } from "react";
import { useTable, usePagination, useFlexLayout, useFilters, Row, HeaderGroup, Cell } from "react-table-next";
import { BsChevronDoubleRight } from "@react-icons/all-files/bs/BsChevronDoubleRight";
import { BsChevronDoubleLeft } from "@react-icons/all-files/bs/BsChevronDoubleLeft";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack";

import { IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "Molecules";
import { Box, Flex, Stack, Text } from "Atoms";
import { DefaultColumnFilter, defaultFilterMethod } from "./PosInvoiceCustomerTable";
import { ContactPerson, InvoiceCustomer } from "Types";

type ExtendedHeaderGroup = {
    filterable?: boolean;
} & HeaderGroup<ContactPerson>;

type Props = {
    selectedInvoiceCustomer: InvoiceCustomer | null;
    selectedContactPerson: ContactPerson | null;
    setSelectedContactPerson: (person: ContactPerson | null) => void;
};

export const PosInvoiceContactTable: React.FC<Props> = ({
    selectedInvoiceCustomer,
    selectedContactPerson,
    setSelectedContactPerson
}) => {
    if (!selectedInvoiceCustomer) {
        return null;
    }

    const data = selectedInvoiceCustomer.contactPersons;

    const columns = useMemo(
        () => [
            {
                Header: "Namn",
                accessor: "name",
                Filter: DefaultColumnFilter,
                filterMethod: defaultFilterMethod,
                filterable: true
            },
            {
                Header: "Tel. nr",
                accessor: "phoneNumber",
                Filter: DefaultColumnFilter,
                filterMethod: defaultFilterMethod,
                filterable: true
            }
        ],
        []
    );

    const tableInstance = useTable(
        {
            //@ts-ignore
            columns,
            data,
            initialState: {
                //@ts-ignore
                pageIndex: 0,
                pageSize: 7
            },
            autoResetFilters: false,
            autoResetPage: false
        },
        useFilters,
        useFlexLayout,
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow } = tableInstance;
    //@ts-ignore
    const { page, gotoPage, nextPage, previousPage, pageOptions, canPreviousPage, canNextPage } = tableInstance;
    const {
        //@ts-ignore
        state: { pageIndex },
        //@ts-ignore
        pageCount
    } = tableInstance;

    return (
        <Flex flex="1" direction="column" height="auto">
            <Flex flex="1" direction="column" pl={6}>
                <Table
                    {...getTableProps()}
                    wrapperProps={{
                        border: "none",
                        rounded: "none",
                        boxShadow: "none",
                        overflow: "auto"
                    }}
                >
                    <TableHead>
                        {headerGroups.map((headerGroup: ExtendedHeaderGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((header: ExtendedHeaderGroup, index: number) => {
                                    return (
                                        <TableHeader
                                            position="relative"
                                            {...header.getHeaderProps()}
                                            flexDirection="column"
                                            justifyContent="center"
                                            py={2}
                                            px={3}
                                        >
                                            <Box textAlign={"center"}>
                                                {/** @ts-ignore */}
                                                {header.render("Header")}
                                            </Box>
                                            <Box pt={2} w="98%">
                                                {/** @ts-ignore */}
                                                {header.filterable ? header.render("Filter") : null}
                                            </Box>
                                        </TableHeader>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()} width="max-content">
                        {page.map((row: Row<ContactPerson>, index: number) => {
                            prepareRow(row);
                            const contactName = row.original.name;
                            const isNameSelected = selectedContactPerson?.name === contactName;
                            let rowColour = index % 2 === 0 ? "gray.100" : "white";
                            if (isNameSelected) {
                                rowColour = "blue.200";
                            }
                            return (
                                <React.Fragment key={row.id}>
                                    <TableRow
                                        fontSize="xl"
                                        bg={rowColour}
                                        {...row.getRowProps()}
                                        onClick={() => setSelectedContactPerson(row.original)}
                                    >
                                        {row.cells.map((cell: Cell<ContactPerson>) => {
                                            return (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    overflowX="hidden"
                                                    textOverflow="ellipsis"
                                                >
                                                    {cell.value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                    <TableFooter display="flex" justifyContent="center" alignItems="center">
                        <TableRow>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Flex>
            <Flex flex="1" justifyContent="flex-end" direction="column" alignItems="center" mt={2}>
                <Text
                    as="span"
                    fontSize="lg"
                    letterSpacing="1px"
                    color="gray.600"
                    alignItems="center"
                    fontWeight="bold"
                    pl={3}
                    mb={2}
                >
                    Sida {pageIndex + 1} av {pageOptions.length}
                </Text>

                <Stack isInline stretch={2} alignItems="flex-end" height="auto">
                    <IconButton
                        size="xl"
                        icon={BsChevronDoubleLeft}
                        onClick={() => gotoPage(0)}
                        isDisabled={!canPreviousPage}
                    />
                    <IconButton size="xl" icon={IoIosArrowBack} onClick={previousPage} isDisabled={!canPreviousPage} />
                    <IconButton size="xl" icon={IoIosArrowForward} onClick={nextPage} isDisabled={!canNextPage} />
                    <IconButton
                        size="xl"
                        icon={BsChevronDoubleRight}
                        onClick={() => gotoPage(pageCount - 1)}
                        isDisabled={!canNextPage}
                    />
                </Stack>
            </Flex>
        </Flex>
    );
};
