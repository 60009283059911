import { useEffect, useState } from "react";

import { PaymentMethod, paymentTabsHeaderTexts, paymentTabStatusTypes, POS_MODE, SPLIT_TYPE } from "Constants";
import { isAlreadyPaid } from "../../utils";
import {
    useSplitCashRegister,
    usePos,
    findActivePaymentTabsByStatuses,
    getNewOrderWindowId,
    getOrderWindowsForTable,
    getVisibleOrderWindows,
    useDiscounts
} from "Providers";
import { OrderWindow, PaymentTab, DISCOUNT_STATE, PaymentTabs } from "Types";
import { useOrderWindowsStore, usePosStore, useTableStore } from "Stores";

const DIMMER_TIMER_MS = 3000;
const {
    APPROVED,
    CANCELLED,
    FAILED,
    DT_CREATED,
    DT_ONGOING,
    POSTPONED_CREATED,
    POSTPONED_ONGOING,
    POSTPONED_EXTENDED,
    TABLE_EXTENDED
} = paymentTabStatusTypes;

const handleUpdateDTTab = (
    activeOrderWindowId: string,
    DTInfo: any,
    updateActivePaymentTabStatus: (
        activeOrderWindowId: string,
        operationSuccess: boolean,
        headerText: string,
        displayText: string,
        // TODO: Should create an enum from the values in  paymentTabStatusTypes.values()
        status: any
    ) => {}
) => {
    updateActivePaymentTabStatus(
        activeOrderWindowId,
        true,
        "Order lagd",
        `Order Nr: ${DTInfo.orderNumber}`,
        DT_ONGOING
    );
};

const findPaymentTab = (selectedStatus: any, activePaymentTabs: PaymentTabs) => {
    const paymentTabArray = Object.values(activePaymentTabs);
    return paymentTabArray.find(paymentTab => paymentTab.status === selectedStatus);
};

export const usePaymentHandler = (
    updateActivePaymentTabStatus: () => {},
    clearOrderWindow: (orderWindowId: string, newActiveOrderWindowId?: string | null) => OrderWindow[],
    activePaymentTabs: PaymentTabs,
    resetTerminalIdForPaymentTab: (terminalId: string) => {},
    hideOrderWindow: (orderWindowIds: string[]) => {}
) => {
    const { activeTerminalIds, setActiveTerminalIds, saveAndClearActivePaymentTab } = usePosStore();
    const [disabledCancelBtns, setDisabledCancelBtns] = useState([]);

    const {
        posMode,
        splitCartProducts,
        fromOrderWindowId,
        to: toOrderWindowIds,
        splitMode,
        isSplitModePaymentComplete,
        handlePaymentStarted,
        removeToPayCartProducts,
        getSplitOrderWindowIds,
        ...splitCashRegister
    } = useSplitCashRegister();
    const {
        switchCombinedDiscountState: { setComboDiscountState, comboDiscountState }
    } = useDiscounts();
    const {
        orderWindows,
        split,
        setSplit,
        emptyCart,
        refetchProductsStock,
        clearOrderWindows,
        setActiveOrderWindowId
    } = useOrderWindowsStore();
    const tableService = useTableStore();
    const hasSelectedTable = !!tableService?.selectedTable;

    const handleRemoveActiveTerminal = (activePaymentTabTerminalId?: string) => {
        setActiveTerminalIds(
            activeTerminalIds.filter(activeTerminalId => activeTerminalId !== activePaymentTabTerminalId)
        );
    };

    const handleDisabledCancelBts = (activePaymentTabTerminalId?: String) => {
        setDisabledCancelBtns(currDisabledCancelBtns =>
            currDisabledCancelBtns.filter(cancelBtn => cancelBtn !== activePaymentTabTerminalId)
        );
    };

    useEffect(() => {
        const dtOngoingTimeout = createTimeoutForDTOngoing(activePaymentTabs);
        const dtCreatedTimeout = createTimeoutForDTCreated(activePaymentTabs);

        const postponesTimeout = createTimeOutForPostpones(activePaymentTabs);
        const postponeOngoingTimeout = createTimeoutForPostponedOngoing(activePaymentTabs);

        const failedTimeout = createTimeoutForFailed(activePaymentTabs);
        const cancelledTimeout = createTimeoutForCancelled(activePaymentTabs);
        const approvedTimeout = createTimeoutForApproved(activePaymentTabs);

        return () => {
            dtOngoingTimeout && clearTimeout(dtOngoingTimeout);
            dtCreatedTimeout && clearTimeout(dtCreatedTimeout);
            postponesTimeout && clearTimeout(postponesTimeout);
            failedTimeout && clearTimeout(failedTimeout);
            cancelledTimeout && clearTimeout(cancelledTimeout);
            approvedTimeout && clearTimeout(approvedTimeout);
            postponeOngoingTimeout && clearTimeout(postponeOngoingTimeout);
        };
    }, [activePaymentTabs]);

    const onFinishedApprovedPayment = (approvedPaymentTab: PaymentTab) => {
        if (approvedPaymentTab.paymentMethod === PaymentMethod.CARD) {
            handleRemoveActiveTerminal(approvedPaymentTab.terminalId);
            handleDisabledCancelBts(approvedPaymentTab.terminalId);
        }
        saveAndClearActivePaymentTab(approvedPaymentTab.id);

        let updatedOrderWindows: OrderWindow[] = getVisibleOrderWindows(
            orderWindows,
            tableService.selectedTable?.id || null,
            getSplitOrderWindowIds()
        );

        tableService.checkResetCloseTable();

        if (posMode === POS_MODE.REGULAR) {
            updatedOrderWindows = clearOrderWindow(approvedPaymentTab.id);
        } else {
            handlePaymentStarted(true);
            const isPaymentComplete = isSplitModePaymentComplete(approvedPaymentTab.id);

            /**
             * Paid all cartProducts in the original fromOrderWindow
             */
            if (isPaymentComplete && !!fromOrderWindowId) {
                const fromOrderWindow = emptyCart(fromOrderWindowId);
                splitCashRegister.clearCartProducts();
                updatedOrderWindows = clearOrderWindows(toOrderWindowIds, fromOrderWindowId, fromOrderWindow);
            } else {
                // Based on the splitMode we have to handle upserting of orderWindows differently
                // If we split on DISHES, we only want to clear the orderWindow and keep it in the state
                // If we split on NUMBER or AMOUNT we want to REMOVE the orderWindow from the state

                const shouldRemoveOrderWindow = [SPLIT_TYPE.AMOUNT, SPLIT_TYPE.NUMBER].includes(splitMode);

                if (shouldRemoveOrderWindow) {
                    const unpaidOrderWindows = toOrderWindowIds.filter(
                        orderWindowId => orderWindowId != approvedPaymentTab.id
                    );

                    const nextOrderWindowId = unpaidOrderWindows[unpaidOrderWindows.length - 1];

                    updatedOrderWindows = clearOrderWindow(approvedPaymentTab.id, nextOrderWindowId);
                    // Update to orderWindow ids after successful payment
                    setSplit({
                        ...split,
                        to: unpaidOrderWindows
                    });
                } else {
                    emptyCart(approvedPaymentTab.id);
                    removeToPayCartProducts(splitCartProducts.toPay);
                }
            }
        }

        if (hasSelectedTable) {
            // When only one orderWindow, we can exit table service mode
            // When multiple, user might want to continue paying orderWindows

            const tableOrderWindows = getOrderWindowsForTable(updatedOrderWindows, tableService.selectedTable!.id);

            // clearOrderWindow on line 161 has already set the correct activeOrderWindowId
            // setting it again in splitMode will give us a wrong active id.
            // This code is obviously not optimal and needs to be discussed.
            if (posMode === POS_MODE.REGULAR) {
                const activeOrderWindowIndex = tableOrderWindows.findIndex(
                    tableOrderWindow => tableOrderWindow.id === approvedPaymentTab.id
                );
                setActiveOrderWindowId(getNewOrderWindowId(activeOrderWindowIndex, tableOrderWindows));
            }
            const hasNoMoreTableOrderWindows = tableOrderWindows.length === 0;
            if (hasNoMoreTableOrderWindows) {
                tableService.onExitTableMode();
            }
        }

        if (comboDiscountState === DISCOUNT_STATE.OFF) {
            setComboDiscountState(DISCOUNT_STATE.ON);
        }

        refetchProductsStock();
    };

    const createTimeoutForApproved = (activePaymentTabs: PaymentTabs) => {
        const approvedPaymentTab = findPaymentTab(APPROVED, activePaymentTabs);
        if (!approvedPaymentTab) return;

        if (approvedPaymentTab.displayHeaderText === paymentTabsHeaderTexts.APPROVED) {
            return setTimeout(() => {
                onFinishedApprovedPayment(approvedPaymentTab);
            }, DIMMER_TIMER_MS);
        }
    };

    const createTimeoutForCancelled = (activePaymentTabs: PaymentTabs) => {
        const cancelledPaymentTab = findPaymentTab(CANCELLED, activePaymentTabs);
        if (!cancelledPaymentTab) return;
        const timeoutMs = cancelledPaymentTab.paymentMethod === PaymentMethod.CARD ? 5000 : 1000;

        return setTimeout(() => {
            if (cancelledPaymentTab.driveThrough) {
                resetTerminalIdForPaymentTab(cancelledPaymentTab.id);
                handleDisabledCancelBts(cancelledPaymentTab.terminalId);
            } else {
                if (cancelledPaymentTab.paymentMethod === PaymentMethod.CARD) {
                    handleRemoveActiveTerminal(cancelledPaymentTab.terminalId);
                    handleDisabledCancelBts(cancelledPaymentTab.terminalId);
                }
                saveAndClearActivePaymentTab(cancelledPaymentTab.id);
            }
            refetchProductsStock();
        }, timeoutMs);
    };

    const createTimeoutForFailed = (activePaymentTabs: PaymentTabs) => {
        const failedPaymentTab = findPaymentTab(FAILED, activePaymentTabs);
        if (!failedPaymentTab) return;
        if (isAlreadyPaid(failedPaymentTab.displayHeaderText)) return;

        return setTimeout(() => {
            if (failedPaymentTab.driveThrough) {
                resetTerminalIdForPaymentTab(failedPaymentTab.id);
                handleDisabledCancelBts(failedPaymentTab.terminalId);
            } else {
                if (failedPaymentTab.paymentMethod === PaymentMethod.CARD) {
                    handleRemoveActiveTerminal(failedPaymentTab.terminalId);
                    handleDisabledCancelBts(failedPaymentTab.terminalId);
                }
                saveAndClearActivePaymentTab(failedPaymentTab.id);
            }
            refetchProductsStock();
        }, DIMMER_TIMER_MS);
    };

    const createTimeoutForPostponedOngoing = (activePaymentTabs: PaymentTabs) => {
        const failedPaymentTab = findPaymentTab(POSTPONED_ONGOING, activePaymentTabs);
        if (!failedPaymentTab) return;

        return setTimeout(() => {
            saveAndClearActivePaymentTab(failedPaymentTab.id);

            if (hasSelectedTable) {
                tableService.onExitTableMode();
            } else {
                hideOrderWindow([failedPaymentTab.id]);
            }
        }, DIMMER_TIMER_MS);
    };

    const createTimeOutForPostpones = (activePaymentTabs: PaymentTabs) => {
        const statues = [POSTPONED_CREATED, POSTPONED_EXTENDED, TABLE_EXTENDED];
        const postponeTabs = findActivePaymentTabsByStatuses(statues, activePaymentTabs);

        if (!postponeTabs.length) return;

        return setTimeout(() => {
            // DT_EXTENDED has been deprecated and become POSTPONE_EXTENDED.
            // Reasons:
            // 1. DT_EXTENDED and POSTPONE_EXTENDED did the exact same things
            // 2. Extending POSTPONE orders resulted in creating new orders due to wrong use of PaymentMethod
            // 3. DT_EXTENDED used extendPostponedPaymentOrder which is the same mutation POSTPONE_EXTENDED has to use
            const postponedDTTab = postponeTabs.find(postponeTab => !!postponeTab?.DTInfo?.orderId);
            if (!!postponedDTTab) {
                handleUpdateDTTab(postponedDTTab.id, postponedDTTab.DTInfo, updateActivePaymentTabStatus);
            }

            postponeTabs
                .filter(postponeTab => !postponeTab?.DTInfo?.orderId)
                .forEach(postponeTab => saveAndClearActivePaymentTab(postponeTab.id));

            if (hasSelectedTable) {
                const hasTableExtended = postponeTabs.some(
                    value => value.status === TABLE_EXTENDED && value.paymentMethod === POSTPONED_EXTENDED
                );
                if (!hasTableExtended) {
                    tableService.onExitTableMode();
                }
            }
        }, DIMMER_TIMER_MS);
    };

    const createTimeoutForDTCreated = (activePaymentTabs: PaymentTabs) => {
        const DTCreatedTab = findPaymentTab(DT_CREATED, activePaymentTabs);
        if (!DTCreatedTab) return;

        return setTimeout(() => {
            handleUpdateDTTab(DTCreatedTab.id, DTCreatedTab.DTInfo, updateActivePaymentTabStatus);
        }, DIMMER_TIMER_MS);
    };

    const createTimeoutForDTOngoing = (activePaymentTabs: PaymentTabs) => {
        const DTOngoingTab = findPaymentTab(DT_ONGOING, activePaymentTabs);
        if (!DTOngoingTab) return null;

        return setTimeout(() => {
            setDisabledCancelBtns(curr => curr.filter(c => c !== DTOngoingTab.terminalId));
        }, DIMMER_TIMER_MS);
    };

    return [disabledCancelBtns, setDisabledCancelBtns, onFinishedApprovedPayment];
};
