import React from "react";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import Truncate from "react-truncate";

import { SpringButton } from "TempUtils";
import { Box, NewGrid, Text } from "Atoms";
import { MenuProductCategory } from "Types";

type Props = {
    menuProductCategories: MenuProductCategory[];
    handleActiveCategory: Function;
    activeCategoryId: string;
};

export const PosMenuCategories: React.FC<Props> = ({
    menuProductCategories,
    handleActiveCategory,
    activeCategoryId
}) => (
    <NewGrid gap={6} overflowX="auto">
        {menuProductCategories
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .filter(cat => cat.name !== "Bästsäljare")
            .map((category, i) => {
                const isSelected = activeCategoryId === category.id;

                let colors: any = {};
                if (isSelected) {
                    colors = {
                        backgroundColor: "#8bca9d",
                        borderColor: "#8bca9d",
                        categoryColor: "white"
                    };
                } else {
                    colors = {
                        backgroundColor: category.color ? category.color : "white",
                        borderColor: "gray.400",
                        categoryColor: "gray.900"
                    };
                }

                const { categoryColor, ...buttonColors } = colors;

                return (
                    <SpringButton
                        key={`menu-cat-${category.name}-${i}`}
                        {...buttonColors}
                        whiteSpace="normal"
                        flexDirection="column"
                        h="7rem"
                        rounded="lg"
                        borderWidth="2px"
                        borderStyle="solid"
                        _hover={undefined}
                        customOnClick={() => handleActiveCategory(category)}
                    >
                        <Text wordBreak="break-all" fontSize="xl">
                            {/** @ts-ignore */}
                            <Truncate width={350}>{category.name}</Truncate>
                        </Text>
                        {category.posFavorite && <Box as={FaStar} size="20px" color="yellow.400" mr={1} />}
                    </SpringButton>
                );
            })}
    </NewGrid>
);
